/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Typography } from "@mui/material";
import MotionElement from "../../../components/MotionElement/MotionElement";
import { motionStyles, styles } from "../Booking.styles";
import { isMobile } from "react-device-detect";

interface IDateComponent {
  date: Date;
  index: number;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  selectedDate: string;
}

const DateComponent = ({
  date,
  index,
  setSelectedDate,
  selectedDate,
}: IDateComponent) => {
  const mainControls = useAnimation();

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (
      selectedDate ===
      `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    ) {
      mainControls.start("selected");
      return;
    }
    mainControls.start("initial");
  }, [selectedDate]);
  return (
    <div style={{ margin: "50px 2% 20px 2%" }}>
      <MotionElement
        duration={1}
        hide={{ opacity: 0, scale: 0.5, x: 100 }}
        show={{ opacity: 1, scale: 1, x: 0 }}
        delay={index / 20 + .3}
        renderNow={true}
        // reverse={reverse}
      >
        <motion.div
          whileHover={{
            scale: isMobile ? 0.9 : 1.2,
            // backgroundColor: ["hsl(0, 0, 90)", "hsl(0, 0, 96)"],
          }}
          variants={{
            initial: {
              scale: 1,
              backgroundColor: "hsl(0, 0, 96)",
            },
            selected: {
              backgroundColor: ["hsl(0, 0, 96)", "hsl(0, 0, 90)"],
            },
          }}
          initial="initial"
          animate={mainControls}
          transition={{
            type: "spring",
            bounce: 0.5,
          }}
          style={{
            ...motionStyles.dateCard,
            borderRadius: ".7rem",
            cursor: isMobile ? "default" : "pointer",
          }}
          onClick={() => {
            setSelectedDate(
              `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
            );
          }}
        >
          <Typography
            style={{
              ...styles.heading,
              fontSize: ".8rem",
              margin: "5%",
            }}
          >
            {weekday[date.getDay()]} {date.getDate()}
          </Typography>

          <Typography
            style={{
              ...styles.typography,
              fontSize: ".7rem",
              marginBottom: "5px",
            }}
          >
            {month[date.getMonth()]}
          </Typography>
        </motion.div>
      </MotionElement>
    </div>
  );
};
export default DateComponent;
