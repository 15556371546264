/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import NumberEach from "./NumberEach";

interface INumbers {
  finalValue: number;
}

const Numbers = (props: INumbers) => {
  const defaultPlaces = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const [xPosition, setXPosition] = useState(0);
  const [places, setPlaces] = useState(defaultPlaces);

  const mainControls = useAnimation();

  useEffect(() => {
    mainControls.start("move_x");
  }, [xPosition]);

  useEffect(() => {
    //to move numbers horizontally with width 18px
    setXPosition(-180 + (props.finalValue.toString().length - 1) * 18);

    let arr = props.finalValue
      .toString()
      .split("")
      .map((val) => Number(val));

    let splicedArr = [...defaultPlaces];

    splicedArr.splice(11 - arr.length, arr.length, ...arr);
    setPlaces(splicedArr);
  }, [props.finalValue]);

  return (
    <>
      <div
        style={{
          width: `${places.length * 18}px`,
          overflow: "hidden",
        }}
      >
        <motion.div
          variants={{
            initial_x: { x: -162 },
            move_x: { x: xPosition },
          }}
          initial="initial_x"
          animate={mainControls}
          transition={{
            duration: 1,
            delay: 0,
            type: "ease",
            bounce: 0.5,
          }}
          style={{ display: "flex" }}
        >
          {places.map((number, index) => {
            return (
              <NumberEach
                key={index}
                finalValue={number}
                xPosition={xPosition}
                position={index + 1}
              />
            );
          })}
        </motion.div>
      </div>
    </>
  );
};

export default Numbers;
