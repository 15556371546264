import AnimatedCircle from "./AnimatedCircle";

export default function Circles({
  color = "black",
  loading = false,
  background = "transparent",
  scale = 1,
}) {
  const circlesArr1 = [
    1.1 * scale,
    2 * scale,
    2.6 * scale,
    2 * scale,
    1.1 * scale,
  ];
  const circlesArr2 = [1.1 * scale, 2 * scale, 1.1 * scale];
  const circlesArr3 = [
    2.6 * scale,
    2 * scale,
    1.1 * scale,
    2 * scale,
    2.6 * scale,
  ];

  return (
    <>
      <div
        style={{
          width: `${16 * scale}rem`,
          height: `${16 * scale}rem`,
          border: `${0.5 * scale}rem solid ${color}`,
          borderRadius: `${16}rem`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          background: background,
        }}
      >
        {/* Row 1 */}

        <div
          style={{
            width: `${11 * scale}rem`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10%",
          }}
        >
          {circlesArr1.map((cir, index) => (
            <AnimatedCircle
              key={index}
              cir={cir}
              color={color}
              loading={loading}
            />
          ))}
        </div>

        {/* Row 2*/}

        <div
          style={{
            width: `${11 * scale}rem`,
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "-1%",
          }}
        >
          <AnimatedCircle
            cir={2 * scale}
            color={color}
            left="-4%"
            position="absolute"
            loading={loading}
          />

          {circlesArr2.map((cir, index) => (
            <AnimatedCircle
              key={index}
              cir={cir}
              color={color}
              margin="5%"
              loading={loading}
            />
          ))}
          <AnimatedCircle
            cir={2 * scale}
            color={color}
            right="-4%"
            position="absolute"
            loading={loading}
          />
        </div>

        {/* Row 3 */}

        <div
          style={{
            width: `${12.6 * scale}rem`,
            //   border: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            top: "-5%",
          }}
        >
          {circlesArr3.map((cir, index) => (
            <AnimatedCircle
              key={index}
              cir={cir}
              color={color}
              loading={loading}
            />
          ))}
        </div>

        {/* Row 4 */}

        <div
          style={{
            width: `${11 * scale}rem`,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            position: "relative",
            top: "-8%",
          }}
        >
          <AnimatedCircle
            cir={2 * scale}
            color={color}
            left="-4%"
            position="absolute"
            loading={loading}
          />
          {circlesArr2.map((cir, index) => (
            <AnimatedCircle
              key={index}
              cir={cir}
              color={color}
              margin="5%"
              loading={loading}
            />
          ))}
          <AnimatedCircle
            cir={2 * scale}
            color={color}
            right="-4%"
            position="absolute"
            loading={loading}
          />
        </div>

        {/* Row 5 */}

        <div
          style={{
            width: `${11 * scale}rem`,
            //   border: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "-8%",
          }}
        >
          {circlesArr1.map((cir, index) => (
            <AnimatedCircle
              key={index}
              cir={cir}
              color={color}
              loading={loading}
            />
          ))}
        </div>
      </div>
    </>
  );
}
