import { useContext } from "react";
import { Typography } from "@mui/material";
import "../admin.labels.json";
import labels from "../admin.labels.json";
import Numbers from "./Numbers/Numbers";
import { WindowDimensions } from "../../../App";

const textStyles = {
  fontSize: `1.5rem`,
  color: "ghostwhite",
  fontFamily: "jost",
  margin: "10px",
  fontWeight: "bold",
};

const textDiv = {
  display: "flex",
  alignItems: "center",
};

interface IMonthlyRecords {
  players: number;
  ownerCommision: number;
  staffCommision: number;
  total: number;
}

interface IData {
  data: IMonthlyRecords;
}

export default function ReportData(props: IData) {
  const windowDimensions = useContext(WindowDimensions);

  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "10px",
        border: "2px solid ghostwhite",
        transform:
          windowDimensions.width < 500
            ? `scale(${windowDimensions.width / 555})`
            : "scale(1)",
      }}
    >
      <div style={textDiv}>
        <Typography style={textStyles}>{`${labels.players} : ₹`}</Typography>
        <Numbers finalValue={props.data.players} />
      </div>
      <div style={textDiv}>
        <Typography style={textStyles}>{`${labels.staff} : ₹`}</Typography>
        <Numbers finalValue={Math.round(props.data.staffCommision)} />
      </div>
      <div style={textDiv}>
        <Typography style={textStyles}>{`${labels.partner} : ₹`}</Typography>
        <Numbers finalValue={Math.round(props.data.ownerCommision)} />
      </div>
      <div style={textDiv}>
        <Typography style={textStyles}>{`${labels.total} : ₹`}</Typography>
        <Numbers finalValue={Math.round(props.data.total)} />
      </div>
    </div>
  );
}
