import React, { useState, useContext, useEffect } from "react";
import { Typography } from "@mui/material";
import "./adminStyles.css";
// import { WindowDimensions } from "../../App";
import Circles from "../../components/Logo/Circles";
import TextInput from "../../components/TextInput";
import MotionElement from "../../components/MotionElement/MotionElement";
import Button from "../../components/Button";
import request from "../../custom/request";
import { url } from "../../custom/constants";
import Snackbar from "../../components/Snackbar/Snackbar";
import Record from "./Record/Record";
import Report from "./AdminReport/Report";
import labels from "./admin.labels.json";

export default function Admin() {
  // const windowDimensions = useContext(WindowDimensions);
  const [loading, setLoading] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [snackText, setSnackText] = useState("");
  const [variant, setVariant] = useState<"error" | "success" | "warning">(
    "success"
  );
  const [openSnack, setOpenSnack] = useState(false);
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    document.addEventListener("logout", (e) => {
      const event = e as CustomEvent;
      localStorage.setItem("laserx-login", "false");
      if (event.detail.user === "staff") {
        setTimeout(() => {
          setShowLogin(true);
          setShowRecord(false);
        }, 700);
        return;
      }

      setTimeout(() => {
        setShowLogin(true);
        setShowReport(false);
      }, 700);
    });

    if (localStorage.getItem("laserx-login") === "true") {
      if (localStorage.getItem("laserx-user") === "staff") {
        setTimeout(() => {
          setLoading(false);
          setShowRecord(true);
        }, 2000);
        return;
      }
      request(`${url}/report`, "GET").then((data: any) => {
        if (data.msg === "admin") {
          setReportData(data.result);
          setTimeout(() => {
            setLoading(false);
            setShowLogin(false);
            setShowReport(true);
          }, 2000);
        }
      });
      return;
    }
    setTimeout(() => {
      setLoading(false);
      setShowLogin(true);
    }, 2000);

    return document.removeEventListener("logout", (e) => console.log("remove"));
  }, []);
  return (
    <div className="container">
      <div className="login-div">
        <Snackbar
          text={snackText}
          close={() => setOpenSnack(false)}
          open={openSnack}
          variant={variant}
        />
        <div className="logo-div">
          <MotionElement
            delay={2}
            duration={1}
            hide={{ y: 200 }}
            show={{ y: 0 }}
          >
            <Circles color="white" loading={loading} scale={0.3} />
          </MotionElement>
        </div>
        {showLogin && (
          <>
            <MotionElement
              duration={0.5}
              hide={{ scale: 0 }}
              show={{ scale: 1 }}
              reverse={reverse}
              type="ease"
            >
              <TextInput
                value={user}
                placeholder={labels.username}
                onChange={(e) => setUser(e.target.value)}
              />
            </MotionElement>
            <MotionElement
              duration={0.6}
              hide={{ scale: 0 }}
              show={{ scale: 1 }}
              reverse={reverse}
              type="ease"
            >
              <TextInput
                type="password"
                value={password}
                placeholder={labels.password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MotionElement>
            <MotionElement
              duration={0.7}
              hide={{ scale: 0 }}
              show={{ scale: 1 }}
              reverse={reverse}
              type="ease"
            >
              <Button
                onClick={() => {
                  if (!user || !password) {
                    setOpenSnack(true);
                    setSnackText(labels.empty);
                    return;
                  }
                  setLoading(true);
                  request(`${url}/login`, "POST", {
                    user,
                    password,
                  }).then((data: any) => {
                    setTimeout(() => {
                      setLoading((p) => !p);
                    }, 1000);
                    if (data.msg === false) {
                      setOpenSnack(true);
                      setVariant("error");
                      setSnackText(labels.notFound);
                      return;
                    }
                    if (data.msg === "invalid") {
                      setOpenSnack(true);
                      setVariant("error");
                      setSnackText(labels.incorrect);
                      return;
                    }
                    if (data.msg === true) {
                      localStorage.setItem("laserx-login", "true");
                      localStorage.setItem("laserx-user", "staff");
                      setReverse(true);
                      setTimeout(() => {
                        setUser("");
                        setPassword("");
                        setShowLogin(false);
                        setShowRecord(true);
                      }, 700);
                      return;
                    }
                    if (data.msg === "admin") {
                      localStorage.setItem("laserx-login", "true");
                      localStorage.setItem("laserx-user", "admin");
                      setReportData(data.result);
                      setReverse(true);
                      setTimeout(() => {
                        setUser("");
                        setPassword("");
                        setShowLogin(false);
                        setShowReport(true);
                      }, 700);
                      return;
                    }
                  });
                }}
              >
                <Typography
                  style={{
                    fontSize: `1.2rem`,
                    color: "ghostwhite",
                    fontFamily: "jost",
                  }}
                >
                  {labels.login}
                </Typography>
              </Button>
            </MotionElement>
          </>
        )}

        {showRecord && <Record setLoading={setLoading} />}
        {showReport && (
          <Report setLoading={setLoading} reportData={reportData} />
        )}
      </div>
    </div>
  );
}
