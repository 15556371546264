import React, { useEffect, useState } from "react";
import { useSpring, animated, easings } from "react-spring";

interface IAnimCircle {
  color?: string;
  cir: number;
  margin?: string;
  right?: string;
  left?: string;
  position?: "relative" | "absolute";
  loading?: boolean;
}

export default function AnimatedCircle({
  color = "black",
  cir,
  margin = "0%",
  right = "",
  left = "",
  position = "relative",
  loading = false,
}: IAnimCircle) {
  const [anim, setAnim] = useState(false);

  let randomColor = Math.floor(Math.random() * 16777215).toString(16);
  let randNum = Math.random() * 1000;

  const { animate } = useSpring({
    from: { animate: loading ? 0 : 0.5 },
    animate: anim ? 1 : loading ? 0 : 0.5,
    loop: loading ? true : false,
    config: { duration: 1200, easing: easings.linear },
  });

  const props = useSpring({
    opacity: anim ? 1 : 0,
    background: anim ? `#${randomColor}` : color,
    config: { duration: 4800, easing: easings.linear },
  });

  useEffect(() => {
    setTimeout(() => {
      loading && setAnim(true);
    }, randNum);
  }, []);

  useEffect(() => {
    if (!loading) {
      setAnim(false);
    }
    if(loading){
      setTimeout(() => {
        loading && setAnim(true);
      }, randNum);
    }
  }, [loading]);

  return (
    <animated.div
      style={{
        width: `${cir}rem`,
        height: `${cir}rem`,
        borderRadius: `${cir}rem`,
        background: color,
        margin: margin,
        right: right,
        left: left,
        position: position,
        opacity: animate.to({
          range: [0, 0.5, 1],
          output: [0, 1, 0],
        }),
        transform: animate.to({
          range: [0, 0.5, 1],
          output: ["scale(0)", "scale(1)", "scale(0)"],
        }),
      }}
    />
  );
}
