import React, { useContext } from "react";
import { motion, useAnimation } from "framer-motion";
import { isMobile } from "react-device-detect";
import "./index.css";
import { WindowDimensions } from "../../App";

interface IButton {
  children: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  style?: React.CSSProperties;
}

export default function Button(props: IButton) {
  const windowDimensions = useContext(WindowDimensions);
  const buttonControls = useAnimation();

  return (
    <motion.button
      variants={{
        error: { x: -20 },
        initial: { x: 0 },
      }}
      initial="initial"
      animate={buttonControls}
      whileHover={{ scale: isMobile ? 0.9 : 1.1 }}
      transition={{
        duration: 1,
        type: "spring",
        bounce: 0.5,
      }}
      className="button"
      style={{
        ...props.style,
        width: `${windowDimensions.width / 8}px`,
        cursor: isMobile ? "default" : "pointer",
      }}
      onClick={props.onClick}
    >
      {props.children}
    </motion.button>
  );
}
