/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "../../screens/BookingPage/Booking.styles";
import { createPortal } from "react-dom";
import { motion, useAnimation } from "framer-motion";
import { WindowDimensions } from "../../App";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./Snackbar.css";

interface ISnack {
  text: string;
  close: () => void;
  autoClose?: number;
  open: boolean;
  variant?: "warning" | "error" | "success";
}

const Snackbar = ({
  text,
  close,
  autoClose = 3000,
  open = false,
  variant = "success",
}: ISnack) => {
  const windowDimensions = useContext(WindowDimensions);
  const mainControls = useAnimation();

  useEffect(() => {
    if (open) {
      mainControls.start("show");
      const timeOut = setTimeout(() => {
        mainControls.start("hide");
        setTimeout(() => {
          close();
        }, 200);
      }, autoClose);
      return () => clearTimeout(timeOut);
    }
  }, [open]);

  return createPortal(
    <>
      {open && (
        <motion.div
          className="snack"
          style={{
            maxWidth: windowDimensions.width <= 500 ? "80vw" : "fitContent",
            justifyContent: "space-around",
          }}
          variants={{
            hide: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hide"
          animate={mainControls}
          transition={{ duration: 0.2, delay: 0 }}
        >
          {variant === "error" ? (
            <ErrorOutlineIcon style={{ marginRight: "10px" }} />
          ) : null}

          <Typography
            style={{
              ...styles.heading,
              fontSize: "1rem",
            }}
          >
            {text}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              mainControls.start("hide");
              setTimeout(() => {
                close();
              }, 200);
            }}
          >
            <CloseIcon sx={{ color: "black", fontSize: "1.2rem" }} />
          </IconButton>
        </motion.div>
      )}
    </>,
    document.body
  );
};

export default Snackbar;
