import { useEffect, useState, useContext } from "react";
import "../adminStyles.css";
import MotionElement from "../../../components/MotionElement/MotionElement";
import { Typography, IconButton } from "@mui/material";
import Select from "./Select";
import labels from "../admin.labels.json";
import LogoutIcon from "@mui/icons-material/Logout";
import { CreateEvent } from "../../../custom/customEvent";
import ReportData from "./ReportData";
import { WindowDimensions } from "../../../App";

interface IRecord {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reportData: any;
}

interface IMonthlyRecords {
  players: number;
  ownerCommision: number;
  staffCommision: number;
  total: number;
}

export default function Report(props: IRecord) {
  const windowDimensions = useContext(WindowDimensions);

  const [reverse, setReverse] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [data, setData] = useState<IMonthlyRecords>({
    players: 0,
    ownerCommision: 0,
    staffCommision: 0,
    total: 0,
  });

  const monthKey = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  useEffect(() => {
    if (!selectedYear) {
      setData({
        players: 0,
        ownerCommision: 0,
        staffCommision: 0,
        total: 0,
      });
    }

    if (selectedYear && selectedMonth) {
      if (props.reportData[selectedYear][selectedMonth]) {
        setData(props.reportData[selectedYear][selectedMonth]);
        return;
      }
      setData({
        players: 0,
        ownerCommision: 0,
        staffCommision: 0,
        total: 0,
      });
      return;
    }
    if (selectedYear) {
      let record = {
        players: 0,
        ownerCommision: 0,
        staffCommision: 0,
        total: 0,
      };
      Object.keys(props.reportData[selectedYear]).forEach((key) => {
        record.players += props.reportData[selectedYear][key].players;
        record.ownerCommision +=
          props.reportData[selectedYear][key].ownerCommision;
        record.staffCommision +=
          props.reportData[selectedYear][key].staffCommision;
        record.total += props.reportData[selectedYear][key].total;
      });

      setData(record);
    }
  }, [selectedYear, selectedMonth]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <>
        <MotionElement
          duration={0.7}
          hide={{ scale: 0 }}
          show={{ scale: 1 }}
          reverse={reverse}
          type="ease"
        >
          <Typography
            style={{
              fontSize: `2.2rem`,
              color: "ghostwhite",
              fontFamily: "jost",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            {labels.year}
          </Typography>
        </MotionElement>
        <div style={{ display: "flex" }}>
          {Object.keys(props.reportData).map((key, index) => {
            return (
              <Select
                key={index}
                index={index}
                reverse={reverse}
                value={key}
                select="year"
                setSelect={setSelectedYear}
                selectedItem={selectedYear}
              />
            );
          })}
        </div>
        <MotionElement
          duration={0.7}
          hide={{ scale: 0 }}
          show={{ scale: 1 }}
          reverse={reverse}
          type="ease"
        >
          <Typography
            style={{
              fontSize: `2.2rem`,
              color: "ghostwhite",
              fontFamily: "jost",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            {labels.month}
          </Typography>
        </MotionElement>
        <div
          className="horizontal-scroll"
          style={{
            width: windowDimensions.width >= 950 ? "80vw" : "100vw",
            justifyContent: windowDimensions.width >= 1200 ? "center" : "",
          }}
        >
          {monthKey.map((key, index) => {
            return (
              <Select
                key={index}
                index={index}
                reverse={reverse}
                value={key}
                select="month"
                setSelect={setSelectedMonth}
                selectedItem={selectedMonth}
              />
            );
          })}
        </div>
        <MotionElement
          duration={0.7}
          hide={{ scale: 0 }}
          show={{ scale: 1 }}
          reverse={reverse}
          type="ease"
        >
          <ReportData data={data} />
        </MotionElement>
        <div style={{ marginTop: "50px" }}>
          <MotionElement
            duration={0.8}
            hide={{ scale: 0 }}
            show={{ scale: 1 }}
            reverse={reverse}
            type="ease"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setReverse(true);
                const logout = CreateEvent("logout", { msg: "logout" }, true);
                document.dispatchEvent(logout);
              }}
            >
              <LogoutIcon sx={{ color: "ghostwhite", fontSize: "1.8rem" }} />
            </IconButton>
          </MotionElement>
        </div>
      </>
    </div>
  );
}
