import React, { useState, forwardRef } from "react";
import "./index.css";
interface CustomInputProps extends React.ComponentProps<"input"> {
  type?: string;
  value?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
}

const TextInput = forwardRef<HTMLInputElement, CustomInputProps>(
  (
    { type = "text", value = "", placeholder = "", style, className, ...props },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div
        className={`input-container  ${className}`}
        style={{
          ...style,
        }}
      >
        {/* Floating Label */}
        <label
          className="label"
          style={{
            position: "absolute",
            top: isFocused || value ? "10%" : "50%",
            fontSize: isFocused || value ? "12px" : "16px",
            color: isFocused ? "#3f51b5" : "#757575",
          }}
        >
          {placeholder}
        </label>

        {/* Input Field */}
        <input
          {...props}
          ref={ref}
          type={type}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={`input`}
        />
      </div>
    );
  }
);

export default TextInput;
