import React, { useState, useEffect, createContext } from "react";
import HomePage from "./screens/HomePage/HomePage";
import StartPage from "./screens/LoadingPage/LoadingPage";
import Booking from "./screens/BookingPage/Booking";
import Confirmation from "./screens/ConfirmationPage/Confirmation";
import Player from "./screens/PlayerInfoPage/Player";
import Admin from "./screens/AdminPage/Admin";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import getWindowDimensions from "./custom/windowDimensions";
import { StoreProvider } from "easy-peasy";
import { store } from "./custom/store/store";

interface IWindow {
  width: number;
  height: number;
}

export const WindowDimensions = createContext<IWindow>({ width: 0, height: 0 });

function App() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <StoreProvider store={store}>
      <WindowDimensions.Provider value={windowDimensions}>
        <Router>
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/book" element={<Booking />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/player" element={<Player />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </Router>
      </WindowDimensions.Provider>
    </StoreProvider>
  );
}

export default App;
