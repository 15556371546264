import { useState } from "react";
import "../adminStyles.css";
import MotionElement from "../../../components/MotionElement/MotionElement";
import { Typography, IconButton } from "@mui/material";
import Button from "../../../components/Button";
import request from "../../../custom/request";
import Select from "./Select";
import labels from "../admin.labels.json";
import { url } from "../../../custom/constants";
import DialogBox from "../../../components/DialogBox/DialogBox";
import Snackbar from "../../../components/Snackbar/Snackbar";
import LogoutIcon from "@mui/icons-material/Logout";
import { CreateEvent } from "../../../custom/customEvent";

interface IRecord {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Record(props: IRecord) {
  const [reverse, setReverse] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState(0);
  const [selectedSession, setSelectedSession] = useState(0);
  const [variant, setVariant] = useState<"error" | "success" | "warning">(
    "success"
  );
  const [snackText, setSnackText] = useState("");

  const players = [4, 5, 6, 7, 8];
  const session = [1, 2, 3];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <>
        <Snackbar
          text={snackText}
          close={() => setOpenSnack(false)}
          open={openSnack}
          variant={variant}
        />
        <DialogBox heading="Confirm" setOpen={setOpen} open={open}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: `1.5rem`,
                color: "black",
                fontFamily: "jost",
              }}
            >
              {`${labels.players} : ${selectedPlayers}`}
            </Typography>
            <Typography
              style={{
                fontSize: `1.5rem`,
                color: "black",
                fontFamily: "jost",
              }}
            >
              {`${labels.session} : ${selectedSession}`}
            </Typography>
            <Button
              style={{ borderColor: "#ff5d00" }}
              onClick={() => {
                props.setLoading(true);
                setOpen(false);
                request(`${url}/createRecord`, "POST", {
                  players: selectedPlayers,
                  session: selectedSession,
                }).then((data: any) => {
                  setSelectedPlayers(0);
                  setSelectedSession(0);
                  setTimeout(() => {
                    props.setLoading(false);
                  }, 1000);
                  if (data.msg) {
                    setOpenSnack(true);
                    setSnackText("Record added.");
                    setVariant("success");
                  }
                });
              }}
            >
              <Typography
                style={{
                  fontSize: `1.2rem`,
                  color: "#ff5d00",
                  fontFamily: "jost",
                }}
              >
                {labels.submit}
              </Typography>
            </Button>
          </div>
        </DialogBox>
        <MotionElement
          duration={0.7}
          hide={{ scale: 0 }}
          show={{ scale: 1 }}
          reverse={reverse}
          type="ease"
        >
          <Typography
            style={{
              fontSize: `2.2rem`,
              color: "ghostwhite",
              fontFamily: "jost",
              marginBottom: "10px",
            }}
          >
            {labels.players}
          </Typography>
        </MotionElement>
        <div style={{ display: "flex" }}>
          {players.map((item, index) => {
            return (
              <Select
                key={index}
                index={index}
                reverse={reverse}
                item={item}
                select="players"
                setSelect={setSelectedPlayers}
                selectedItem={selectedPlayers}
              />
            );
          })}
        </div>
        <MotionElement
          duration={0.7}
          hide={{ scale: 0 }}
          show={{ scale: 1 }}
          reverse={reverse}
          type="ease"
        >
          <Typography
            style={{
              fontSize: `2.2rem`,
              color: "ghostwhite",
              fontFamily: "jost",
              margin: "10px",
            }}
          >
            {labels.session}
          </Typography>
        </MotionElement>
        <div style={{ marginBottom: "50px", display: "flex" }}>
          {session.map((item, index) => {
            return (
              <Select
                key={index}
                index={index}
                reverse={reverse}
                item={item}
                select="session"
                setSelect={setSelectedSession}
                selectedItem={selectedSession}
              />
            );
          })}
        </div>
        <MotionElement
          duration={0.7}
          hide={{ scale: 0 }}
          show={{ scale: 1 }}
          reverse={reverse}
          type="ease"
        >
          <Button
            onClick={() => {
              if (selectedPlayers && selectedSession) {
                setOpen(true);
                return;
              }
              setOpenSnack(true);
              setSnackText("Please select options.");
              setVariant("error");
            }}
          >
            <Typography
              style={{
                fontSize: `1.2rem`,
                color: "ghostwhite",
                fontFamily: "jost",
              }}
            >
              {labels.submit}
            </Typography>
          </Button>
        </MotionElement>
        <div style={{ marginTop: "50px" }}>
          <MotionElement
            duration={0.8}
            hide={{ scale: 0 }}
            show={{ scale: 1 }}
            reverse={reverse}
            type="ease"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setReverse(true);
                const logout = CreateEvent("logout", { user: "staff" }, true);
                document.dispatchEvent(logout);
              }}
            >
              <LogoutIcon sx={{ color: "ghostwhite", fontSize: "1.8rem" }} />
            </IconButton>
          </MotionElement>
        </div>
      </>
    </div>
  );
}
