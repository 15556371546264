/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";

const textStyles = {
  fontSize: `1.5rem`,
  color: "ghostwhite",
  fontFamily: "jost",
  fontWeight: "bold",
};

interface INumbers {
  finalValue: number;
  xPosition: number;
  position: number;
}

export default function NumberEach(props: INumbers) {
  const [yPosition, setYPosition] = useState(40);

  const mainControls = useAnimation();
  const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  useEffect(() => {
    mainControls.start("move_y");
  }, [yPosition]);

  useEffect(() => {
    setYPosition(props.finalValue * -40);
  }, [props.finalValue]);

  return (
    <div
      style={{
        height: "40px",
        width: "18px",
        overflow: "hidden",
      }}
    >
      {numbers.map((number, index) => {
        return (
          <motion.div
            key={index}
            variants={{
              initial_y: { y: 40 },
              move_y: { y: yPosition },
            }}
            initial="initial_y"
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            animate={mainControls}
            transition={{
              duration: 0.7,
              delay: 0,
              type: "ease",
              bounce: 0.5,
            }}
          >
            <Typography style={textStyles}>{number}</Typography>
          </motion.div>
        );
      })}
    </div>
  );
}
