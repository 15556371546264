/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import MotionElement from "../../../components/MotionElement/MotionElement";
import { Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { isMobile } from "react-device-detect";
import "../adminStyles.css";

interface ISelect {
  index: number;
  reverse: boolean;
  item: number;
  selectedItem: number;
  select: "players" | "session";
  setSelect: React.Dispatch<React.SetStateAction<number>>;
}

export default function Select(props: ISelect) {
  const mainControls = useAnimation();

  useEffect(() => {
    if (props.item !== props.selectedItem) {
      mainControls.start("initial");
    }
  }, [props.selectedItem]);

  return (
    <MotionElement
      duration={1}
      hide={{ opacity: 0, scale: 0.5, x: 100 }}
      show={{ opacity: 1, scale: 1, x: 0 }}
      delay={props.index / 20}
      renderNow={true}
      reverse={props.reverse}
    >
      <motion.div
        whileHover={{
          scale: isMobile ? 0.9 : 1.2,
        }}
        variants={{
          initial: {
            scale: 1,
            backgroundColor: "hsl(0, 0, 96)",
            boxShadow: "1px 2px 12px 1px gainsboro",
          },
          selected: {
            backgroundColor: ["hsl(0, 0, 96)", "hsl(143, 50%, 52%)"],
            boxShadow: "1px 2px 12px 1px hsl(143, 50%, 52%)",
          },
        }}
        initial="initial"
        animate={mainControls}
        transition={{
          type: "spring",
          bounce: 0.5,
        }}
        className="record-data"
        style={{
          cursor: isMobile ? "default" : "pointer",
        }}
        onClick={() => {
          props.setSelect(props.item);
          mainControls.start("selected");
        }}
      >
        <Typography
          style={{
            fontSize: ".8rem",
            margin: "5%",
          }}
        >
          {props.item}
        </Typography>
      </motion.div>
    </MotionElement>
  );
}
